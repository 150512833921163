import React, { useCallback } from 'react';

import { ThemeColor, AtomButtonSize } from '../../types/atom.d';

import { ButtonBase, SvgIcon, ButtonLoader } from './styles';
import CircularProgress from 'src/comps/Atom/CircularProgress';

export interface ButtonProps {
  borderColor?: ThemeColor;
  backgroundColor?: ThemeColor;
  srgbBorderColor?: { r: number; g: number; b: number };
  srgbBackgroundColor?: { r: number; g: number; b: number };
  className?: string;
  children?: React.ReactNode;
  color?: ThemeColor;
  disabled?: boolean;
  fullWidth?: boolean;
  isLoading?: boolean;
  leftIcon?: React.ReactNode;
  loaderPosition?: 'content' | 'iconLeft' | 'iconRight';
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void | undefined;
  rightIcon?: React.ReactNode;
  size: AtomButtonSize;
  type?: 'submit' | 'reset' | 'button';
}

export interface ButtonLinkProps {
  as?: "a" | "button";
  href?: string,
  target?: '_self' | '_blank' | '_parent' | '_top'
}

const Button: React.FC<ButtonProps & ButtonLinkProps> = React.forwardRef<HTMLButtonElement, ButtonProps & ButtonLinkProps>(({
  className = '',
  children,
  color,
  disabled = false,
  rightIcon,
  fullWidth = false,
  isLoading = false,
  loaderPosition,
  onClick,
  size = 'm',
  leftIcon,
  type = 'button',
  ...props
}, ref) => {
  const smallLoaderPosition = size === 's' ? 'content' : 'iconLeft';
  const btnLoaderPosition = loaderPosition || smallLoaderPosition;

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (props.as !== 'a') {
        event.preventDefault();
      }
      if (onClick && !disabled && !isLoading) {
        onClick(event);
      }
    },
    [disabled, isLoading, onClick, props.as],
  );

  return (
    <ButtonBase
      {...props}
      className={className}
      color={color}
      disabled={disabled}
      fullWidth={fullWidth}
      isLoading={isLoading}
      loaderPosition={btnLoaderPosition}
      onClick={handleClick}
      ref={ref}
      size={size}
      type={type}
    >
      {isLoading && btnLoaderPosition === 'content' && !disabled && (
        <ButtonLoader color={color} isInline margin="0" />
      )}
      <>
        {isLoading && btnLoaderPosition === 'iconLeft' ? (
          <SvgIcon size={size}>
            <CircularProgress data-testid="loader" size="s" color={color} />
          </SvgIcon>
        ) : (
          leftIcon && <SvgIcon size={size}>{leftIcon}</SvgIcon>
        )}
        {children}
        {isLoading && btnLoaderPosition === 'iconRight' ? (
          <SvgIcon size={size}>
            <CircularProgress data-testid="loader" size="s" color={color} />
          </SvgIcon>
        ) : (
          rightIcon && <SvgIcon size={size}>{rightIcon}</SvgIcon>
        )}
      </>
    </ButtonBase>
  );
});

export default Button;
