import { jsonld, descriptions, sameAs } from './constants';
import { mapJsonLdBreadcrumb, mapJsonLdTypeDetail } from './mappers';

export function setJsonLdBreadcrumb(params) {
  return JSON.stringify(mapJsonLdBreadcrumb(params));
}

export function setJsonLdAccommodation(accommodation, addressParam) {
  return JSON.stringify(mapJsonLdTypeDetail(accommodation, addressParam));
}

export function setSameAsByMarket(market) {
  const linkedinMarket =
    market !== 'es' && market !== 'de' && market !== 'fr' && market !== 'pt'
      ? `https://www.linkedin.com/company/stayforlong`
      : `https://${market}.linkedin.com/company/stayforlong`;

  const twitterMarket =
    market === 'es' ? 'https://twitter.com/stayforlong_es' : 'https://twitter.com/stayforlong';

  return [...sameAs, linkedinMarket, twitterMarket];
}

export function getJsonLd(market) {
  const jsonLdMarket = {
    ...jsonld,
    sameAs: setSameAsByMarket(market),
  };

  if (!descriptions[market]) {
    return JSON.stringify(jsonLdMarket);
  }

  return JSON.stringify({
    ...jsonLdMarket,
    description: descriptions[market],
  });
}
