import React from 'react';
import NextHead from 'next/head';
import { useIntl } from 'react-intl';

import { BRAND_NAME_TITLE } from 'src/constants/business';
import { getJsonLd } from 'client/components/JsonLd/utils';
import { buildCanonicalURL } from 'lib/utils/page';

import HrefLangs from 'src/comps/HrefLangs';
import messages from './messages';
import { useMarket } from 'src/hooks/useMarket';

interface HeadProps {
  pathname?: string;
}

const Head: React.FC<HeadProps> = props => {
  const { pathname = '' } = props;
  const intl = useIntl();
  const { language, market, subdomain, topdomain } = useMarket();

  const title = `${BRAND_NAME_TITLE}: ${intl.formatMessage(messages.title)}`;
  const description = intl.formatMessage(messages.description, { brand: BRAND_NAME_TITLE });
  const canonicalURL = buildCanonicalURL({ market, language, pathname, subdomain, topdomain });

  return (
    <>
      <NextHead>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <link rel="canonical" href={canonicalURL} />
        <script type="application/ld+json">{getJsonLd(market)}</script>
      </NextHead>
      <HrefLangs pathname={pathname} />
    </>
  );
};

export default Head;
