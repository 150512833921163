import styled from 'styled-components';
import Button, { ButtonProps } from 'src/comps/Atom/Buttons/Button';

const PrimaryButton = styled(Button).attrs<ButtonProps>(props => ({
  ...props,
  backgroundColor: 'brand1',
  borderColor: 'brand1',
  color: 'grey8',
})) <typeof Button>``;

export default PrimaryButton;
