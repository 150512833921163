import styled, { css } from 'styled-components';
import { ThemeColor, AtomSize } from '../types/atom.d';
import get from 'lodash/get';

interface ICircularProgress {
  size?: AtomSize;
  color?: ThemeColor;
}

export const smallSize = css`
  width: 20px;
  height: 20px;
`;

export const mediumSize = css`
  width: 50px;
  height: 50px;
`;

const largeSize = css`
  width: 80px;
  height: 80px;
`;

export const CircularProgressBase = styled.svg.attrs<ICircularProgress>(props => ({
  color: props.color ? get(props.theme, `colors.${props.color}`) : props.theme.colors.brand1,
  size: props.size || 'medium',
}))<ICircularProgress>`
  animation: rotate 1s linear infinite;
  ${props => props.size === 's' && smallSize}
  ${props => props.size === 'm' && mediumSize}
  ${props => props.size === 'l' && largeSize}

  & .path {
    stroke: ${props => props.color};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;
