import styled, { css } from 'styled-components';
import get from 'lodash/get';

import Loader from 'src/comps/Atom/Loader';
import { ButtonProps } from 'src/comps/Atom/Buttons/Button';

import { AtomButtonSize } from 'src/comps/Atom/types/atom.d';

export const smallSize = css`
  font-size: ${props => props.theme.fonts.sizes.s};
  line-height: 143%;
  padding: 4px 10px;
`;

export const mediumSize = css`
  font-size: ${props => props.theme.fonts.sizes.m};
  line-height: 150%;
  padding: 10px 18px;
`;

const fullWidth = css`
  width: 100%;
`;

export const ButtonBase = styled.button.attrs<ButtonProps>(props => ({
  color: props.color ? get(props.theme, `colors.${props.color}`) : props.theme.colors.grey8,
  backgroundColor: props.backgroundColor
    ? get(props.theme, `colors.${props.backgroundColor}`)
    : props.theme.colors.grey0,
  srgbBackgroundColor: props.backgroundColor
    ? get(props.theme, `colors.srgb.${props.backgroundColor}`)
    : props.theme.colors.srgb.grey0,
  borderColor: props.borderColor
    ? get(props.theme, `colors.${props.borderColor}`)
    : props.theme.colors.grey0,
  srgbBorderColor: props.borderColor
    ? get(props.theme, `colors.srgb.${props.borderColor}`)
    : props.theme.colors.srgb.grey0,
}))<ButtonProps>`
  appearance: none;
  border: none;
  cursor: ${props => (props.isLoading ? 'not-allowed' : 'pointer')};
  pointer-events: ${props => (props.isLoading ? 'none' : 'auto')};
  font-family: ${props => props.theme.fonts.families.primary};
  font-weight: 700;
  position: relative;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  border: 2px solid ${props => props.borderColor};
  border-radius: 8px;

  ${props => (props.size === 's' ? smallSize : mediumSize)}

  ${props => props.fullWidth && fullWidth}

  svg {
    fill: ${props => props.color};
  }

  :hover,
  :active {
    outline: none;
    text-decoration: none;
    color: ${props => props.color};
    background-color: color(
      srgb calc(((${props => props.srgbBackgroundColor?.r} * 80) / 255) * 1%)
        calc(((${props => props.srgbBackgroundColor?.g} * 80) / 255) * 1%)
        calc(((${props => props.srgbBackgroundColor?.b} * 80) / 255) * 1%)
    );
    border: 2px solid
      color(
        srgb calc(((${props => props.srgbBorderColor?.r} * 80) / 255) * 1%)
          calc(((${props => props.srgbBorderColor?.g} * 80) / 255) * 1%)
          calc(((${props => props.srgbBorderColor?.b} * 80) / 255) * 1%)
      );
  }

  ${props =>
    props.isLoading &&
    css`
      background-color: color(
        srgb calc(((${props.srgbBackgroundColor?.r} * 80) / 255) * 1%)
          calc(((${props.srgbBackgroundColor?.g} * 80) / 255) * 1%)
          calc(((${props.srgbBackgroundColor?.b} * 80) / 255) * 1%)
      );
      border: 2px solid
        color(
          srgb calc(((${props.srgbBorderColor?.r} * 80) / 255) * 1%)
            calc(((${props.srgbBorderColor?.g} * 80) / 255) * 1%)
            calc(((${props.srgbBorderColor?.b} * 80) / 255) * 1%)
        );
    `}

  ${props =>
    props.isLoading &&
    props.loaderPosition === 'content' &&
    css`
      color: transparent;

      &:hover,
      &:active {
        color: transparent;
      }

      svg {
        fill: transparent;
      }
    `}

  &[disabled] {
    background-color: ${props => props.theme.colors.grey5};
    border-color: ${props => props.theme.colors.grey5};
    color: ${props => props.theme.colors.grey4};
    cursor: 'not-allowed';
    pointer-events: none;

    &:hover {
      background-color: inherit;
      opacity: 1;
    }

    svg {
      fill: ${props => props.theme.colors.grey4};
    }
  }
`;

export const SvgIcon = styled.span.attrs<{ size: AtomButtonSize }>(() => ({
  size: '20px',
}))<{ size: AtomButtonSize }>`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: ${props => props.size};
    height: ${props => props.size};
  }
`;

export const ButtonLoader = styled(Loader).attrs(props => ({
  color: props.color ? get(props.theme, `colors.${props.color}`) : props.theme.colors.grey8,
}))`
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
`;
