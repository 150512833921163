export const jsonld = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  address: {
    '@type': 'PostalAddress',
    addressCountry: 'ES',
    addressLocality: 'Barcelona',
    addressRegion: 'Barcelona',
    postalCode: '08010',
    streetAddress: 'Ronda Sant Pere 17, 3º 4ª',
  },
  founders: [
    { '@type': 'Person', name: 'Luis Osorio Solé' },
    { '@type': 'Person', name: 'Francesc López Castellet' },
    { '@type': 'Person', name: 'Raúl Morón Sillero' },
  ],
  foundingDate: '2015-01-29',
  foundingLocation: 'Barcelona',
  legalName: 'Stayforlong S.L.',
  logo: {
    '@type': 'ImageObject',
    caption: 'Stayforlong logo',
    url: 'https://static.stayforlong.com/web/images/sfl-logo-mobile.jpg',
  },
  name: 'Stayforlong',
  url: 'https://www.stayforlong.com',
};

export const descriptions = {
  es:
    'Stayforlong es un sitio web de reservas hoteleras especializado en las reservas de larga estancia. Se encuentra presente en 22 países distintos y ofrece a sus clientes una cartera formada por más de 720.000 establecimientos hoteleros. Stayforlong tiene su sede en la ciudad de Barcelona.',
  de:
    'Stayforlong ist eine Hotelreservierungswebseite spezialisiert auf Langzeitaufenthalte. Wir sind präsent in 22 verschiedenen Ländern und bieten unseren Kunden ein Portfolio von mehr als 720.000 Hotels. Stayforlong hat den Hauptsitz in Barcelona.',
  fr:
    "Stayforlong est un portail de réservation d'hôtels spécialisé dans les longs séjours.Nous sommes présents dans 22 pays et proposons à nos utilisateurs plus de 720.000 hôtels différents.",
  uk:
    "Stayforlong is a hotel reservation portal specialized in long stays. We are present in 22 countries and offer to our clients more than 720.000 different hotels. Stayforlong's headquarter is located in Barcelona, Spain.",
  pt:
    'Stayforlong é um portal de reservas de hotéis especializado em estadias longas. Estamos presentes em 22 países e oferecemos aos nossos usuários mais de 720.000 hotéis diferentes.',
};

export const sameAs = [
  'https://www.facebook.com/stayforlong',
  'https://www.instagram.com/stayforlong',
  'https://www.youtube.com/channel/UCXY0iWptc19iyKlVqkK1ZGw',
];
