import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'page.home.title',
  },
  description: {
    id: 'page.home.description',
  },
  bannerText: {
    id: 'banner.text',
  },
  bannerTitle: {
    id: 'banner.title',
  },
  homeTitle: {
    id: 'hero.best-hotels-for-your-holidays',
  },
});

export default messages;
