import { DEFAULT_COUNTRY } from 'src/constants/countries';

export function setAddress({
  street,
  locality,
  region,
  country = DEFAULT_COUNTRY.market,
  postalCode,
}) {
  return {
    '@type': 'PostalAddress',
    streetAddress: street,
    addressLocality: locality,
    addressRegion: region,
    postalCode,
    addressCountry: country,
  };
}

export const setAmenities = amenities =>
  amenities.map(amenity => ({
    '@type': 'LocationFeatureSpecification',
    value: 'True',
    name: amenity.name,
  }));

export const mapJsonLdTypeDetail = (accommodation, addressParam) => {
  const {
    accommodationType,
    name,
    url,
    photo: image,
    description,
    latitude,
    longitude,
    rating,
    facilities,
  } = accommodation;

  return {
    '@context': 'http://schema.org',
    '@type': accommodationType.toLowerCase(),
    name,
    url,
    image,
    description,
    address: setAddress(addressParam),
    geo: {
      '@type': 'GeoCoordinates',
      latitude,
      longitude,
    },
    starRating: {
      '@type': 'http://schema.org/Rating',
      ratingValue: rating,
    },
    amenityFeature: setAmenities(facilities),
  };
};

export const mapJsonLdBreadcrumb = ({ urlHome, urlList, urlDetail, listTitle, detailTitle }) => ({
  '@context': 'http://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: [
    {
      '@type': 'ListItem',
      position: 1,
      name: 'Home',
      item: urlHome,
    },
    {
      '@type': 'ListItem',
      position: 2,
      name: listTitle,
      item: urlList,
    },
    {
      '@type': 'ListItem',
      position: 3,
      name: detailTitle,
      item: urlDetail,
    },
  ],
});
