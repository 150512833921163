import React from 'react';

import s4lColors from 'src/styles/colors/colors';
import DotNestedKeys from 'src/types/dotNested';
import { AtomSize } from '../types/atom.d';

import { CircularProgressBase } from './styles';

export interface CircularProgressProps {
  className?: string;
  color?: DotNestedKeys<typeof s4lColors>;
  size?: AtomSize;
}

const CircularProgress: React.FC<CircularProgressProps> = ({ className, ...props }) => {
  return (
    <CircularProgressBase {...props} className={className} viewBox="0 0 50 50">
      <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
    </CircularProgressBase>
  );
};

export default CircularProgress;
